import { TCommonError } from '@sobat-bangun/entities';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { createSurvey, createPopupSurvey } from './api';
import { TFormSurvey, TFormCreateSurvey, TReqCreateSurvey } from './types';

export const useCreateSurveyMut = (): UseMutationResult<TFormSurvey, TCommonError, TFormSurvey, null> => {
  return useMutation({
    mutationFn: (params) => createPopupSurvey(params),
  });
};
