import { Route } from '@sobat-bangun/constants';

/* TODO: remove this with explicit import from libs/ui/molecues/Navbar/NavItem/types */
interface TNavItemProps {
  children: React.ReactNode;
  href?: string;
  isActive?: boolean;
}

export const NavigationMenus: TNavItemProps[] = [
  {
    href: '#',
    children: 'Tentang Kami',
  },
  {
    href: '#',
    children: 'Produk & Layanan',
  },
  {
    href: Route.FAQ,
    children: 'FAQ',
  },
];

export const ServiceMenus: Omit<TNavItemProps, 'isActive'>[] = [
  {
    href: Route.RENOVATION,
    children: 'Renovasi',
  },
  {
    href: Route.CONSTRUCTION,
    children: 'Bangun Rumah',
  },
  {
    href: Route.DESIGN,
    children: 'Desain Rumah',
  },
  {
    href: Route.DESIGN_INTERIOR,
    children: 'Desain Interior',
  },
  {
    href: Route.TECHNOLOGY,
    children: 'Teknologi Tambahan',
  },
  {
    href: Route.MATERIALS,
    children: 'Beli Material',
  },
];

export const AboutMenus: Omit<TNavItemProps, 'isActive'>[] = [
  {
    href: '/tentang-kami',
    children: 'Tentang SobatBangun',
  },
  {
    href: Route.POLICY,
    children: 'Kebijakan dan Privasi',
  },
  {
    href: Route.TERMS,
    children: 'Syarat dan Ketentuan',
  },
  {
    href: Route.FAQ,
    children: 'FAQ',
  },
  {
    href: Route.SIGNUP_PARTNER,
    children: 'Daftar Menjadi Mitra',
  },
];
