import { TBaseResponse } from '@sobat-bangun/entities';
import {
  TCitiesResponses,
  TCityParams,
  TCustomerField,
  TDistrictParams,
  TDistrictsResponses,
  TParams,
  TProvincesResponses,
  TReqUpdateProfile,
  TUpdatePhotoReq,
} from './entities';
import { apiBackend } from '@sobat-bangun/services';

export const getProfile = async (): Promise<TCustomerField> => {
  const { data } = await apiBackend.get<TBaseResponse<TCustomerField>>('/users/profile/customers');

  return data.data;
};

export const updateProfileRequest = async (params: TReqUpdateProfile): Promise<boolean> => {
  const { data } = await apiBackend.patch<TBaseResponse<boolean>>('/users/profile/customers', params);

  return data.data;
};

export const getProvinceList = async ({ page = 1, per_page = 10, keyword }: TParams): Promise<TProvincesResponses> => {
  const response = await apiBackend.get<TProvincesResponses>('/regencies/provinces', {
    params: {
      page: page,
      per_page: per_page,
      keyword: keyword,
    },
  });

  return response.data;
};

export const getCityList = async ({ page = 1, per_page = 10, keyword, province_id }: TCityParams): Promise<TCitiesResponses> => {
  const response = await apiBackend.get<TCitiesResponses>('/regencies/cities', {
    params: {
      page: page,
      per_page: per_page,
      keyword: keyword,
      province_id: province_id,
    },
  });

  return response.data;
};

export const getDistrictList = async ({ page = 1, per_page = 10, keyword, city_id }: TDistrictParams): Promise<TDistrictsResponses> => {
  const response = await apiBackend.get<TDistrictsResponses>('/regencies/districts', {
    params: {
      page: page,
      per_page: per_page,
      keyword: keyword,
      city_id: city_id,
    },
  });

  return response.data;
};

export const updatePhoto = async (params: TUpdatePhotoReq): Promise<TBaseResponse<boolean>> => {
  const response = await apiBackend.put<TBaseResponse<boolean>>('/users/profile/customers/update-photo', params);

  return response.data;
};
