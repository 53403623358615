import { TBaseResponse, TCommonError, TList } from '@sobat-bangun/entities';
import { UseInfiniteQueryResult, UseMutationResult, UseQueryResult, useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { getCityList, getDistrictList, getProfile, getProvinceList, updatePhoto, updateProfileRequest } from './api';
import {
  TCitiesResponses,
  TCityParams,
  TCustomerField,
  TDistrictParams,
  TDistrictsResponses,
  TParams,
  TProvincesResponses,
  TReqUpdateProfile,
  TUpdatePhotoReq,
} from './entities';
import { useSession } from 'next-auth/react';

export const useProfileDetail = (): UseQueryResult<TCustomerField, TCommonError> => {
  const session = useSession();

  return useQuery({
    queryKey: ['profile-detail'],
    queryFn: async () => await getProfile(),
    enabled: !!session.data,
  });
};

export const useReqUpdateProfile = (): UseMutationResult<boolean, TCommonError, TReqUpdateProfile, null> => {
  return useMutation({
    mutationFn: (params) => updateProfileRequest(params),
  });
};

export const useUploadPhoto = (): UseMutationResult<TBaseResponse<boolean>, TCommonError, TUpdatePhotoReq, null> => {
  return useMutation({
    mutationFn: (params) => updatePhoto(params),
  });
};

export const useProvinceList = (params: TParams): UseQueryResult<TList[], Error> => {
  return useQuery({
    queryKey: ['provinces', params],
    queryFn: async () => {
      const provinces = await getProvinceList(params);

      return provinces?.data.map((item) => ({ label: item.name, value: item.id }));
    },
    enabled: !!params,
  });
};

export const useInfiniteProvinceList = (params: TParams): UseInfiniteQueryResult<TProvincesResponses, Error> => {
  return useInfiniteQuery({
    queryKey: ['infinite-provinces', params],
    queryFn: async ({ pageParam }) => {
      return await getProvinceList(params ? { ...params, page: pageParam } : {});
    },
    enabled: !!params,
    getNextPageParam: (lastPage) => (lastPage.meta.page !== lastPage.meta.totalPage ? lastPage.meta.page + 1 : undefined),
  });
};

export const useCityListPopup = (params: TCityParams): UseQueryResult<TList[], Error> => {
  return useQuery({
    queryKey: ['cities', params],
    queryFn: async () => {
      const cities = await getCityList(params);

      return cities?.data.map((item) => ({ label: item.name, value: item.name }));
    },
    enabled: !!params,
  });
};

export const useCityList = (params: TCityParams): UseQueryResult<TList[], Error> => {
  return useQuery({
    queryKey: ['cities', params],
    queryFn: async () => {
      const cities = await getCityList(params);

      return cities?.data.map((item) => ({ label: item.name, value: item.id }));
    },
    enabled: !!params,
  });
};

export const useInfiniteCityList = (params: TCityParams): UseInfiniteQueryResult<TCitiesResponses, Error> => {
  return useInfiniteQuery({
    queryKey: ['infinite-cities', params],
    queryFn: async ({ pageParam }) => {
      return await getCityList(params ? { ...params, page: pageParam } : { province_id: undefined });
    },
    enabled: !!params,
    getNextPageParam: (lastPage) => (lastPage.meta.page !== lastPage.meta.totalPage ? lastPage.meta.page + 1 : undefined),
  });
};

export const useDistrictList = (params: TDistrictParams): UseQueryResult<TList[], Error> => {
  return useQuery({
    queryKey: ['districts', params],
    queryFn: async () => {
      const districts = await getDistrictList(params);

      return districts?.data.map((item) => ({ label: item.name, value: item.id }));
    },
    enabled: !!params,
  });
};

export const useInfiniteDistrictList = (params: TDistrictParams): UseInfiniteQueryResult<TDistrictsResponses, Error> => {
  return useInfiniteQuery({
    queryKey: ['infinite-districts', params],
    queryFn: async ({ pageParam }) => {
      return await getDistrictList(params ? { ...params, page: pageParam } : { city_id: undefined });
    },
    enabled: !!params,
    getNextPageParam: (lastPage) => (lastPage.meta.page !== lastPage.meta.totalPage ? lastPage.meta.page + 1 : undefined),
  });
};
