import { Button } from '@sobat-bangun/ui/atoms';
import { ReactElement, FC, useRef, useState, useEffect } from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TFilter, TReqCreateUser, TUser, createValidationSchema } from '../../../../admin/modules/UserManagement/entities';
import Image from 'next/image';
import Link from 'next/link';
import { TLandingBannerProps } from '../types';
import { clsx } from 'clsx';
import { TStrapiImageAttribute } from '@sobat-bangun/entities';
import { ConfirmModal, ConsultationConfirmModal, ConsultationSuccessModal, Stepper } from '@sobat-bangun/ui/molecules';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SurveyModal } from '@sobat-bangun/ui/molecules';
type TReqUser = yup.InferType<typeof createValidationSchema>;
import { useFormContext } from 'react-hook-form';

// awal bikin wizard
import { StepperSurvey } from '@sobat-bangun/ui/molecules';
import { FormProvider } from 'react-hook-form';
import { SuspenseError } from '@sobat-bangun/ui/layouts';
import dynamic from 'next/dynamic';

// start import module survey
import StepError from '../../../modules/Consultation/Survey/Form/error';
import StepLoading from '../../../modules/Consultation/Survey/Form/loading';
import { useCreateSurveyMut } from '../../../modules/Consultation/Survey/hooks';

const StepOneSection = dynamic(() => import('../../../modules/Consultation/Survey/Form/StepOne'), {
  ssr: false,
  loading: () => <StepLoading />,
});

const StepTwoSection = dynamic(() => import('../../../modules/Consultation/Survey/Form/StepTwo'), {
  ssr: false,
  loading: () => <StepLoading />,
});

const StepThreeSection = dynamic(() => import('../../../modules/Consultation/Survey/Form/StepThree'), {
  ssr: false,
  loading: () => <StepLoading />,
});

const StepFourSection = dynamic(() => import('../../../modules/Consultation/Survey/Form/StepFour'), {
  ssr: false,
  loading: () => <StepLoading />,
});

const StepFiveSection = dynamic(() => import('../../../modules/Consultation/Survey/Form/StepFive'), {
  ssr: false,
  loading: () => <StepLoading />,
});

const StepSixSection = dynamic(() => import('../../../modules/Consultation/Survey/Form/StepSix'), {
  ssr: false,
  loading: () => <StepLoading />,
});

const StepSevenSection = dynamic(() => import('../../../modules/Consultation/Survey/Form/StepSeven'), {
  ssr: false,
  loading: () => <StepLoading />,
});
const StepEightSection = dynamic(() => import('../../../modules/Consultation/Survey/Form/StepEight'), {
  ssr: false,
  loading: () => <StepLoading />,
});
const StepNineSection = dynamic(() => import('../../../modules/Consultation/Survey/Form/StepNine'), {
  ssr: false,
  loading: () => <StepLoading />,
});
const StepTenSection = dynamic(() => import('../../../modules/Consultation/Survey/Form/StepTen'), {
  ssr: false,
  loading: () => <StepLoading />,
});
const StepElevenSection = dynamic(() => import('../../../modules/Consultation/Survey/Form/StepEleven'), {
  ssr: false,
  loading: () => <StepLoading />,
});
const StepTwelveSection = dynamic(() => import('../../../modules/Consultation/Survey/Form/StepTwelve'), {
  ssr: false,
  loading: () => <StepLoading />,
});

import { TFormSurvey, TReqCreateSurvey } from '../../../modules/Consultation/Survey/types';
import { toaster } from '@sobat-bangun/utils';
import router from 'next/router';

const Indicator: FC<{ active: boolean; onClick: () => void }> = ({ active, onClick }): ReactElement => (
  <div
    className={clsx('w-[16px] mr-2 h-[3px] md:h-[6px] md:w-[29px] cursor-pointer', {
      'bg-primary-base': active,
      'bg-grey-100 bg-opacity-80': !active,
    })}
    onClick={onClick}
  ></div>
);

const CarouselSection = ({
  banner,
}: {
  banner: Array<{
    id: number;
    redirect_url: string;
    image: TStrapiImageAttribute;
  }>;
}) => {
  const [index, setIndex] = useState(0);
  const draggedRef = useRef(false);
  const clientXDown = useRef(0);
  const intervalRef = useRef(null);
  const radiusSlideMobile = 100;
  const radiusSlideDesktop = 200;
  const [switching, setSwitching] = useState(false);

  const restartSlide = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    if (banner?.length)
      intervalRef.current = setTimeout(() => {
        onNextSlide();
      }, 5000);
  };

  useEffect(() => {
    if (banner?.length === 1) return;
    restartSlide();
  }, []);

  const onAnimate = (cb: () => void) => {
    setSwitching(true);

    setTimeout(() => {
      cb();
      setSwitching(false);
    }, 150);
  };

  const onPrevSlide = () => {
    if (banner?.length === 1) return;
    onAnimate(() => setIndex((old) => (old <= 0 ? banner?.length - 1 : old - 1)));

    restartSlide();
  };

  const onGoToSlide = (index: number) => {
    if (banner?.length === 1) return;
    onAnimate(() => setIndex(index));

    restartSlide();
  };

  const onNextSlide = () => {
    if (banner?.length === 1) return;
    onAnimate(() => setIndex((old) => (old >= banner?.length - 1 ? 0 : old + 1)));

    restartSlide();
  };

  return (
    <div
      className="relative w-full h-full"
      onClick={(e) => {
        if (draggedRef.current) e.preventDefault();
      }}
      onMouseDown={(e) => {
        draggedRef.current = false;
        clientXDown.current = e.clientX;
      }}
      onMouseUp={(e) => {
        if (clientXDown.current - e.clientX > 1 || clientXDown.current - e.clientX < -1) draggedRef.current = true;
        else draggedRef.current = false;

        if (clientXDown.current - e.clientX > radiusSlideDesktop) onNextSlide();
        if (clientXDown.current - e.clientX < radiusSlideDesktop * -1) onPrevSlide();
      }}
      onTouchStart={(e) => {
        draggedRef.current = false;
        clientXDown.current = e.changedTouches[0].clientX;
      }}
      onTouchMove={(e) => {
        if (clientXDown.current - e.changedTouches[0].clientX > 1 || clientXDown.current - e.changedTouches[0].clientX < -1) draggedRef.current = true;
        else draggedRef.current = false;

        if (clientXDown.current - e.changedTouches[0].clientX > radiusSlideMobile) onNextSlide();
        if (clientXDown.current - e.changedTouches[0].clientX < radiusSlideMobile * -1) onPrevSlide();
      }}
    >
      {banner?.length && (
        <div className={`transition-opacity duration-500 ease-in-out ${switching ? 'opacity-0' : 'opacity-100'}`}>
          <Image
            src={banner?.[index]?.image?.data ? banner?.[index].image.data.attributes.url : '/assets/fallback/image_not_available.png'}
            alt={'landing-image'}
            loading="eager"
            fill
            className="object-cover"
            priority
            sizes="(max-width: 650px) 320px, (max-width: 768px) 540px, 960px"
            draggable={false}
          />
        </div>
      )}
      {!banner?.length && (
        <Image
          src={'/assets/fallback/image_not_available.png'}
          alt={'landing-image'}
          loading="eager"
          fill
          className="object-cover"
          priority
          sizes="(max-width: 650px) 320px, (max-width: 768px) 540px, 960px"
          draggable={false}
        />
      )}
      <div className="absolute z-1 flex gap-x-2 bottom-10 right-1/2 translate-x-1/2">
        {banner?.map((_, i) => (
          <Indicator key={i} active={index === i} onClick={() => onGoToSlide(i)} />
        ))}
      </div>
    </div>
  );
};

const HeroSection: FC<TLandingBannerProps> = ({ banner }): ReactElement => {
  const bannerHome = banner?.data?.filter((item) => item.attributes.type === 'HOME')?.[0]?.attributes?.image;
  const bannerHomeMobile = banner?.data?.filter((item) => item.attributes.type === 'HOME')?.[0]?.attributes?.image_mobile;
  const _feature = ['Renovasi', 'Bangun Rumah', 'Desain Rumah', 'Desain Interior', 'Teknologi Tambahan', 'Beli Material'];

  // State Popup
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [timerId, setTimerId] = useState(null);

  // State index form
  const [stepTempIndex, setStepTempIndex] = useState(0);
  const [stepActiveIndex, setStepActiveIndex] = useState(0);

  const [error, setError] = useState(null);
  const useCreatePopupSurvey = useCreateSurveyMut();

  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const methods = useForm<TFormSurvey>();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm<TFormSurvey>({
    mode: 'all',
  });

  const reqSurvey = useCreateSurveyMut();

  const onSubmit = (data) => {
    reqSurvey.mutate(
      {
        ...data,
        nama_lengkap: data.nama_lengkap,
        email: data.email,
        jenis_survei: data.jenis_survei.value,
        tipe_bangunan: data.tipe_bangunan.value,
        luas_bangunan: data.luas_bangunan,
        jenis_partner: data.jenis_partner.value,
        lokasi_proyek: data.lokasi_proyek.value,
        estimasi_biaya: data.estimasi_biaya.value,
        mulai_proyek: data.mulai_proyek.value,
        jenis_desain: data.jenis_desain.value,
        bantuan_biaya: data.bantuan_biaya.value,
        keterangan: data.keterangan,
        handphone: data.handphone,
      },
      {
        onSuccess: () => {
          toaster({ type: 'success', message: 'Selamat, anda sukses input data konsultasi gratis' });
          handleCloseCreateSurvey();
        },
        onError: (errors) => {
          toaster({ type: 'error', message: 'Maaf, terjadi kesalahan proses input data' });
        },
      }
    );
  };

  const handleCloseCreateSurvey = () => {
    reset();
    setError(null);
    setIsOpen(false);
    setStepActiveIndex(0);
    // Popup muncul setelah 3 menit setelah ditutup
    setTimerId(
      setTimeout(() => {
        setIsOpen(true);
        setStepTempIndex(0);
      }, 3 * 60 * 1000) // 3 menit
    );
  };

  const handleOpenModal = () => {
    // Clear Timer Popup
    if (timerId) {
      clearTimeout(timerId);
      setTimerId(null);
    }
    setStepActiveIndex(stepTempIndex);
    setIsOpen(true);
  };

  const handleNext = () => {
    setStepActiveIndex((old) => old + 1);
    setStepTempIndex((old) => old + 1);
  };

  const handlePrev = () => {
    setStepActiveIndex((old) => old - 1);
    setStepTempIndex((old) => old - 1);
  };

  const handleNavigate = () => {
    router.push({ pathname: '/' });
  };

  // Popup muncul setelah 10 detik
  useEffect(() => {
    const initialTimerId = setTimeout(() => setIsOpen(true), 10 * 1000); // 10 detik
    return () => clearTimeout(initialTimerId);
  }, []);

  // akhir bikin wizard
  return (
    <section className="relative h-screen w-screen bg-grey-100">
      <div className="h-full hidden sm:block">
        <CarouselSection banner={bannerHome} />
      </div>
      <div className="h-full block sm:hidden">
        <CarouselSection banner={bannerHomeMobile} />
      </div>
      <div className="flex flex-col absolute top-1/4 z-10 w-full p-6 lg:p-12 h-1/2 pointer-events-none">
        <div className="text-[32px] lg:text-[48px] font-medium text-white leading-light flex flex-col">
          <span>Bangun Rumah Lebih Baik</span>
          <span>
            Bersama <span className="text-primary-base">SobatBangun</span>
          </span>
        </div>

        <div>
          <ul className="flex flex-wrap gap-[20px] w-full  lg:w-[500px] mb-5">
            {_feature.map((item, key) => (
              <li key={key} className="text-white text-[14px] flex gap-x-3 font-[600] items-center">
                <Image width={16} height={16} src="/icon/redot.svg" alt="redot icon" loading={'eager'} />
                {item}
              </li>
            ))}
          </ul>

          <div className="flex flex-col md:flex-row items-start md:items-center gap-4 w-full sm:w-[400px]  mt-4">
            <Link href={'/auth/register'} className="w-full pointer-events-auto" prefetch={false}>
              <Button className="w-full" size="md" fullWidth>
                Daftar SobatBangun
              </Button>
            </Link>
            <Link href="" onClick={handleOpenModal} className="w-full pointer-events-auto">
              <Button className="w-full" variant="ghost-outline" size="md">
                Konsultasi Gratis
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <SurveyModal title="" isOpen={isOpen} handleCancel={handleCloseCreateSurvey} size="md">
        <div className="rounded-lg bg-white py-9">
          <div className={clsx(['px-6 md:px-[50px]', stepActiveIndex < 12 ? 'lg:px-[125px]' : 'lg:px-[74px]'])}>
            {/* <div className="mb-4 sm:mb-[32px]">
              <center>
                <StepperSurvey stepsLabel={['', '', '', '', '', '', '', '', '', '', '', '']} activeIndex={stepActiveIndex} />
              </center>
            </div> */}
            <FormProvider {...methods}>
              {stepActiveIndex === 0 && (
                <SuspenseError fallbackLoading={<StepLoading />} fallbackError={<StepError />}>
                  <StepOneSection />
                </SuspenseError>
              )}
              {stepActiveIndex === 1 && (
                <SuspenseError fallbackLoading={<StepLoading />} fallbackError={<StepError />}>
                  <StepTwoSection />
                </SuspenseError>
              )}
              {stepActiveIndex === 2 && (
                <SuspenseError fallbackLoading={<StepLoading />} fallbackError={<StepError />}>
                  <StepThreeSection />
                </SuspenseError>
              )}
              {stepActiveIndex === 3 && (
                <SuspenseError fallbackLoading={<StepLoading />} fallbackError={<StepError />}>
                  <StepFourSection />
                </SuspenseError>
              )}
              {stepActiveIndex === 4 && (
                <SuspenseError fallbackLoading={<StepLoading />} fallbackError={<StepError />}>
                  <StepFiveSection />
                </SuspenseError>
              )}
              {stepActiveIndex === 5 && (
                <SuspenseError fallbackLoading={<StepLoading />} fallbackError={<StepError />}>
                  <StepSixSection />
                </SuspenseError>
              )}
              {stepActiveIndex === 6 && (
                <SuspenseError fallbackLoading={<StepLoading />} fallbackError={<StepError />}>
                  <StepSevenSection />
                </SuspenseError>
              )}
              {stepActiveIndex === 7 && (
                <SuspenseError fallbackLoading={<StepLoading />} fallbackError={<StepError />}>
                  <StepEightSection />
                </SuspenseError>
              )}
              {stepActiveIndex === 8 && (
                <SuspenseError fallbackLoading={<StepLoading />} fallbackError={<StepError />}>
                  <StepNineSection />
                </SuspenseError>
              )}
              {stepActiveIndex === 9 && (
                <SuspenseError fallbackLoading={<StepLoading />} fallbackError={<StepError />}>
                  <StepTenSection />
                </SuspenseError>
              )}
              {stepActiveIndex === 10 && (
                <SuspenseError fallbackLoading={<StepLoading />} fallbackError={<StepError />}>
                  <StepElevenSection />
                </SuspenseError>
              )}
              {stepActiveIndex === 11 && (
                <SuspenseError fallbackLoading={<StepLoading />} fallbackError={<StepError />}>
                  <StepTwelveSection />
                </SuspenseError>
              )}
              <div className="flex lg:justify-center mt-6 pt-6 gap-x-4">
                {stepActiveIndex > 0 && (
                  <Button type="button" className="w-full lg:w-auto" variant="outline" color="secondary" onClick={handlePrev}>
                    Sebelumnya
                  </Button>
                )}
                {stepActiveIndex < 1 ? (
                  <Button type="button" className="w-full lg:w-auto" variant="solid" onClick={handleNext}>
                    Mulai
                  </Button>
                ) : stepActiveIndex < 11 ? (
                  <Button type="button" className="w-full lg:w-auto" variant="solid" onClick={methods.handleSubmit(handleNext)}>
                    Selanjutnya
                  </Button>
                ) : (
                  stepActiveIndex === 11 && (
                    <Button type="button" className="w-full lg:w-auto" variant="solid" onClick={methods.handleSubmit(onSubmit)}>
                      Kirim
                    </Button>
                  )
                )}
              </div>
            </FormProvider>
          </div>
        </div>
      </SurveyModal>

      <ConfirmModal
        isOpen={isModalSuccessOpen}
        handleOk={handleNavigate}
        handleCancel={handleCloseCreateSurvey}
        title="Konsultasi Gratis Sukses"
        description="Anda telah berhasil mengubah pekerjaan termin."
        showCancel={false}
        okText=""
      />
    </section>
  );
};

export default HeroSection;
