import Head from 'next/head';
import { FC } from 'react';

type THeaderProps = {
  title?: string;
  description?: string;
};

export const Header: FC<THeaderProps> = ({ title, description }: THeaderProps) => {
  const titleText = `${title ? title + ' - Sobat Bangun' : ''}`;

  return (
    <>
      <Head>
        {/* <!-- Primary Meta Tags --> */}
        <title>{titleText}</title>
        <meta name="title" content={titleText}></meta>
        <meta
          name="description"
          content={
            description
              ? description
              : 'SobatBangun adalah Penyedia Jasa Renovasi Rumah Profesional yang dapat Mentransformasikan rumah impian Anda dengan tim ahli kami.'
          }
        />
      </Head>
    </>
  );
};
