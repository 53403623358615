import { toast } from 'react-toastify';
import type { ToastPosition, Theme } from 'react-toastify';
import { TToast } from './types';

const position: ToastPosition = 'top-right';
const autoCloseDuration = 3000;
const theme: Theme = 'light';

export const toaster = (props: TToast) =>
  setTimeout(() => {
    switch (props.type) {
      case 'success':
        return toast.success(props.message, {
          position: position,
          autoClose: autoCloseDuration,
          hideProgressBar: false,
          closeOnClick: false,
          theme: theme,
          onClose: () => console.log('closed'),
        });
      case 'info':
        return toast.info(props.message, {
          position: position,
          autoClose: autoCloseDuration,
          hideProgressBar: false,
          closeOnClick: true,
          theme: theme,
        });
      case 'warning':
        return toast.warning(props.message, {
          position: position,
          autoClose: autoCloseDuration,
          hideProgressBar: false,
          closeOnClick: true,
          theme: theme,
        });
      case 'error':
        return toast.error(props.message, {
          position: position,
          autoClose: autoCloseDuration,
          hideProgressBar: false,
          closeOnClick: true,
          theme: theme,
        });
      default:
        return toast(props.message, {
          position: position,
          autoClose: autoCloseDuration,
          hideProgressBar: false,
          closeOnClick: true,
          theme: theme,
        });
    }
  }, 0);
