import { ErrorBoundary } from '@sentry/nextjs';
import { FC, ReactElement, Suspense } from 'react';
import { TSuspenseProps } from './types';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';

export const SuspenseError: FC<TSuspenseProps> = ({ fallbackError, fallbackLoading, children }): ReactElement => {
  const { reset } = useQueryErrorResetBoundary();
  return (
    <ErrorBoundary onReset={reset} fallback={fallbackError}>
      <Suspense fallback={fallbackLoading}>{children}</Suspense>
    </ErrorBoundary>
  );
};
