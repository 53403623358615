import type { NextPage } from 'next';

const AboutUsSection = dynamic(() => import('../modules/Landing/AboutUsSection'));
const InovationSection = dynamic(() => import('../modules/Landing/InovationSection'));
const JoinSection = dynamic(() => import('../modules/Landing/JoinSection'));
const SocialMediaSection = dynamic(() => import('../modules/Landing/SBTVSection'));
const RekanBahanBangunanSection = dynamic(() => import('../modules/Landing/RekanBahanBangunanSection'));
import HeroSection from '../modules/Landing/HeroSection';

import { Header } from '../utils/helper';
import { Footer, NavbarMain } from '@sobat-bangun/ui/molecules';
import { Fragment, ReactElement, Suspense } from 'react';
import { AboutMenus, ServiceMenus } from '../utils/data.constants';
import { bannerRequest } from '../modules/Common/api';
import { TBannerResponse } from '../modules/Common/types';
import { aboutRequest } from '../modules/AboutUs/api';
import { TAboutResponse } from '../modules/AboutUs/types';
import { partnerRequest } from '../modules/Partner/api';
import { TPartnerResponse } from '../modules/Partner/types';
import { inovationRequest } from '../modules/Innovation/api';
import { TInovationResponse } from '../modules/Innovation/types';
import { NavigationMenus } from '../utils/data.constants';
import { useNotifications } from '../modules/Notifications/hooks';
import { useProfileDetail } from '../modules/Profile/hooks';
import dynamic from 'next/dynamic';
import PromoSection from '../modules/Landing/PromoSection';

type Props = { banner: TBannerResponse; about: TAboutResponse; partner: TPartnerResponse; inovation: TInovationResponse };

const LandingPage: NextPage<Props> = ({ banner, about, partner, inovation }): ReactElement => {
  const { data: notifications } = useNotifications({ page: 1, per_page: 10 });
  const { data: profileDetail } = useProfileDetail();

  return (
    <Fragment>
      <Header title="Home" />
      <NavbarMain topMenus={NavigationMenus} notifications={notifications} image={profileDetail?.photo} name={profileDetail?.name} />
      <HeroSection banner={banner} />
      <Suspense fallback={<div className="h-[950vh]">loading</div>}>
        <AboutUsSection about={about} />
        <PromoSection banner={banner} />
        <InovationSection about={about} partner={partner} inovation={inovation} />
        <JoinSection banner={banner} />
        <SocialMediaSection />
        <RekanBahanBangunanSection />
        <Footer serviceMenu={ServiceMenus} aboutMenu={AboutMenus} />
      </Suspense>
    </Fragment>
  );
};

export async function getStaticProps() {
  let banner = null,
    partner = null,
    about = null,
    inovation = null;
  try {
    banner = await bannerRequest({ populate: 'deep', filters: { type: ['HOME', 'HOME-BOTTOM'] } });
    partner = await partnerRequest({
      populate: 'deep',
      pagination: {
        limit: '4',
      },
      filters: {
        is_show_review: true,
      },
      sort: 'createdAt:DESC',
    });
    about = await aboutRequest({
      populate: 'deep',
    });

    inovation = await inovationRequest({
      populate: 'deep',
      sort: 'createdAt:DESC',
    });
  } catch (err) {
    console.error(err);
  }

  return {
    revalidate: 60, // in seconds
    props: {
      banner,
      about,
      partner,
      inovation,
    },
  };
}

export default LandingPage;
