import { TIndexRequest } from '@sobat-bangun/entities';
import { TDetailNotificationResponse, TNotificationsResponses } from './entities';
import { apiNotification } from '@sobat-bangun/services';

export const notifications = async (params: TIndexRequest): Promise<TNotificationsResponses> => {
  const { data } = await apiNotification.get<TNotificationsResponses>('/notification/notifications', {
    params,
  });
  return data;
};

export const detailNotification = async (id: string): Promise<TDetailNotificationResponse> => {
  const { data } = await apiNotification.get<TDetailNotificationResponse>(`/notification/notifications/${id}`);
  return data;
};
