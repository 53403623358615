import { FC, ReactElement, ReactNode, Ref, forwardRef, useEffect, useRef, useState } from 'react';
import { TLandingBannerProps } from '../types';
import Image from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';

const _banner_data = [
  {
    id: 0,
    src: '/assets/promo-banner.png',
    link: 'https://article.sobatbangun.com/promo-sobatbangun/',
    target: '_blank',
    alt: 'promo bri',
  },
  {
    id: 1,
    src: '/assets/desain-interior-banner.png',
    link: '/layanan/desain-interior',
    target: '',
    alt: 'layanan desain interior',
  },
];

const Indicator: FC<{ active: boolean; onClick: () => void }> = ({ active, onClick }): ReactElement => (
  <div
    className={clsx('w-[16px] mr-2 h-[3px] md:h-[6px] md:w-[29px] cursor-pointer', {
      'bg-primary-base': active,
      'bg-grey-100 bg-opacity-80': !active,
    })}
    onClick={onClick}
  ></div>
);

const BannerContainer = forwardRef((props: { children: ReactNode }, ref: Ref<HTMLDivElement>) => {
  return (
    <div
      ref={ref}
      className="relative items-center bg-white  justify-center w-[full] aspect-[1250/500] rounded-2xl overflow-hidden transition hover:scale-[1.025] hover:shadow-xl"
    >
      {props.children}
    </div>
  );
});

BannerContainer.displayName = 'BannerContainer';

const PromoSection: FC<TLandingBannerProps> = (): ReactElement => {
  const [index, setIndex] = useState(0);
  const draggedRef = useRef(false);
  const clientXDown = useRef(0);
  const intervalRef = useRef(null);
  const radiusSlideMobile = 100;
  const radiusSlideDesktop = 200;
  const [switching, setSwitching] = useState(false);

  const restartSlide = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setTimeout(() => {
      onNextSlide();
    }, 5000);
  };

  useEffect(() => {
    restartSlide();
  }, []);

  const onAnimate = (cb: () => void) => {
    setSwitching(true);

    setTimeout(() => {
      cb();
      setSwitching(false);
    }, 150);
  };

  const onPrevSlide = () => {
    onAnimate(() => setIndex((old) => (old <= 0 ? _banner_data?.length - 1 : old - 1)));

    restartSlide();
  };

  const onGoToSlide = (index: number) => {
    onAnimate(() => setIndex(index));

    restartSlide();
  };

  const onNextSlide = () => {
    onAnimate(() => setIndex((old) => (old >= _banner_data?.length - 1 ? 0 : old + 1)));

    restartSlide();
  };

  return (
    <section
      className="pt-[40px] py-10 lg:pt-20 px-[20px] sm:px-[40px] lg:px-[120px]"
      onClick={(e) => {
        if (draggedRef.current) e.preventDefault();
      }}
      onMouseDown={(e) => {
        draggedRef.current = false;
        clientXDown.current = e.clientX;
      }}
      onMouseUp={(e) => {
        if (clientXDown.current - e.clientX > 1 || clientXDown.current - e.clientX < -1) draggedRef.current = true;
        else draggedRef.current = false;

        if (clientXDown.current - e.clientX > radiusSlideDesktop) onNextSlide();
        if (clientXDown.current - e.clientX < radiusSlideDesktop * -1) onPrevSlide();
      }}
      onTouchStart={(e) => {
        draggedRef.current = false;
        clientXDown.current = e.changedTouches[0].clientX;
      }}
      onTouchMove={(e) => {
        if (clientXDown.current - e.changedTouches[0].clientX > 1 || clientXDown.current - e.changedTouches[0].clientX < -1) draggedRef.current = true;
        else draggedRef.current = false;

        if (clientXDown.current - e.changedTouches[0].clientX > radiusSlideMobile) onNextSlide();
        if (clientXDown.current - e.changedTouches[0].clientX < radiusSlideMobile * -1) onPrevSlide();
      }}
    >
      <BannerContainer>
        <Link href={_banner_data?.[index]?.link} target={_banner_data?.[index]?.target} draggable={false}>
          <div className={`transition-opacity duration-500 ease-in-out ${switching ? 'opacity-0' : 'opacity-100'}`}>
            <Image src={_banner_data?.[index].src} alt={_banner_data?.[index].alt} fill className="object-cover" loading="lazy" draggable={false} />
          </div>
        </Link>
        <div className="absolute z-1 flex gap-x-2 bottom-10 right-1/2 translate-x-1/2">
          {_banner_data?.map((_, i) => (
            <Indicator key={i} active={index === i} onClick={() => onGoToSlide(i)} />
          ))}
        </div>
      </BannerContainer>
    </section>
  );
};

export default PromoSection;
