import { TCommonError, TIndexRequest } from '@sobat-bangun/entities';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { detailNotification, notifications } from './api';
import { TDetailNotificationResponse, TNotificationsResponses } from './entities';
import { useSession } from 'next-auth/react';

export const useNotifications = (params: TIndexRequest): UseQueryResult<TNotificationsResponses, TCommonError> => {
  const session = useSession();
  return useQuery({
    queryKey: ['notifications', params],
    queryFn: async () => await notifications(params),
    enabled: !!params && !!session.data,
    keepPreviousData: true,
  });
};

export const useDetailNotification = (id): UseQueryResult<TDetailNotificationResponse, TCommonError> => {
  return useQuery({
    queryKey: ['detail-notification', id],
    queryFn: async () => await detailNotification(id),
  });
};
