import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Button } from '@sobat-bangun/ui/atoms';
import { clsx } from 'clsx';

/* eslint-disable-next-line */
export interface ConfirmModalProps {
  isOpen: boolean;
  handleCancel: () => void;
  handleOk?: () => void;
  title: string;
  description?: string;
  cancelText?: string;
  okText?: string;
  showCancel?: boolean;
  showSubmit?: boolean;
  children?: ReactNode;
  onOkDisabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  isLoading?: boolean;
}

export const ConfirmModal = ({ showCancel = true, showSubmit = true, size = 'sm', ...props }: ConfirmModalProps) => {
  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog open={props.isOpen} onClose={props.handleCancel} as="div" className="relative z-50 ">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Dialog.Panel
              className={clsx('w-full  rounded-lg bg-white py-6 px-8', {
                'max-w-xl': size === 'sm',
                'max-w-3xl': size === 'md',
                'max-w-7xl': size === 'lg',
              })}
            >
              <Dialog.Title className="text-[20px] font-semibold flex flex-row justify-between">
                <div>{props.title}</div>
                <button onClick={props.handleCancel}>x</button>
              </Dialog.Title>
              <p className="text-[14px] mt-4">{props.description}</p>
              {props.children}
              <div className="grid grid-cols-2 gap-4 mt-4">
                {showCancel && (
                  <Button variant="outline" color="secondary" onClick={props.handleCancel} className="text-xs min-w-[100px]">
                    {props.cancelText || 'Cancel'}
                  </Button>
                )}
                {showSubmit && (
                  <Button
                    onClick={props.handleOk}
                    disabled={props.onOkDisabled}
                    className={`text-xs min-w-[100px] ${!showCancel && 'col-span-2'}`}
                    loading={props.isLoading}
                  >
                    {props.okText || 'Ok'}
                  </Button>
                )}
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ConfirmModal;
