import clsx from 'clsx';
import { ReactElement, forwardRef, Ref, FC } from 'react';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  icon?: React.ReactNode;
  variant?: 'solid' | 'outline' | 'ghost' | 'ghost-outline' | 'secondary';
  loading?: boolean;
  size?: 'sm' | 'md' | 'lg';
  fullWidth?: boolean;
  bold?: boolean;
  color?: 'primary' | 'secondary';
};

export const Button: FC<ButtonProps> = forwardRef(
  (
    { variant = 'solid', disabled = false, children, className = '', loading, size = 'md', fullWidth, color = 'primary', icon, bold = true, onClick, ...rest },
    ref: Ref<HTMLButtonElement>
  ): ReactElement => {
    const contentStyles = clsx('rounded-md flex items-center justify-center gap-x-2', {
      'bg-[#F5333F] ring-2 ring-primary-base text-white disabled:bg-[#F9858C] disabled:ring-0 disabled:cursor-not-allowed': variant === 'solid',
      'bg-white text-[#F5333F] border border-[#F5333F]': variant === 'outline' && color === 'primary',
      'bg-white text-secondary-base border border-secondary-base': variant === 'outline' && color === 'secondary',
      'bg-white text-secondary-base': variant === 'ghost',
      'bg-transparent text-white border border-2 border-white': variant === 'ghost-outline',
      'font-bold': bold,
      'w-full': fullWidth,
    });

    const contentSize = clsx({
      'px-3 py-3 lg:py-4': size === 'lg',
      'px-3 py-2': size === 'md',
      'px-2 py-1 text-sm': size === 'sm',
    });

    const mergedStyles = `${contentSize} ${contentStyles} ${className}`;

    const isDisabled = disabled || loading;

    return (
      <button ref={ref} className={mergedStyles} disabled={isDisabled} onClick={!isDisabled ? onClick : undefined} {...rest}>
        {icon && <span>{icon}</span>}
        {loading ? <SpinAnimation /> : children}
      </button>
    );
  }
);

const SpinAnimation = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-4 h-4">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
      />
    </svg>
  );
};
