import { TBaseResponse } from '@sobat-bangun/entities';
import { TFormSurvey, TReqCreateSurvey } from './types';
import { apiStrapi } from '@sobat-bangun/services';

export const createSurvey = async (params: TReqCreateSurvey): Promise<TReqCreateSurvey> => {
  const { data } = await apiStrapi.post<TBaseResponse<TReqCreateSurvey>>('/konsultasis', params);
  return data.data;
};

export const createPopupSurvey = async (params: TFormSurvey): Promise<TFormSurvey> => {
  console.log(params);
  const { data } = await apiStrapi({
    method: 'POST',
    url: '/konsultasis',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: { data: params },
  });
  return data;
};
